export enum SalesOrderStatus {
  New = 910,
  SendByCustomer = 920,
  Blocked = 925,
  Alert = 930,
  PickAndPack = 940,
  Despatched = 945,
  Cancelled = 950,
}
export enum SalesOrderStockStatus {
  Insufficient = "Insufficient",
  PartiallyDeliverable = "PartiallyDeliverable",
}
