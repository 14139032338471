
import Vue, { VueConstructor } from "vue";
import KCrudTable, {
  CrudTableHeader,
} from "@/modules/crudTable/components/KCrudTable.vue";
import { ExportType, PaginatedRequest } from "@/application/api/getPaginated";
import { mapGetters } from "vuex";
import RequiredClientDialog from "@/modules/client/components/RequiredClientDialog.vue";
import {
  clientSalesOrderIndex,
  SalesOrderIndexItem,
} from "@/modules/salesOrder/api/salesOrderIndex";
import { exportSalesOrders } from "@/modules/salesOrder/api/exportImport";
import { sendBlobToBrowserDownload } from "@/application/util/downloadFile";
import SalesOrderFilter from "@/modules/salesOrder/components/SalesOrderFilter.vue";
import OrderStatusChip from "@/modules/salesOrder/components/OrderStatusChip.vue";
import ExportButton from "@/components/ExportButton.vue";
import dayjs from "@/plugins/dayjs";
import { RouteSetting } from "@/modules/crudTable/store/crudTableStore";
import {
  SalesOrderStatus,
  SalesOrderStockStatus,
} from "@/modules/salesOrder/enums";

interface ComponentData {
  headers: CrudTableHeader[];
  selected: Partial<SalesOrderIndexItem> & { clientId: number; id: number }[];
  filterComponent: VueConstructor;
  defaultFilters: Record<string, unknown>;
  exports: ExportType[];
  salesOrderData: SalesOrderIndexItem[];
}

export default Vue.extend({
  name: "SalesOrderTable",
  components: {
    ExportButton,
    OrderStatusChip,
    RequiredClientDialog,
    KCrudTable,
  },
  data: (): ComponentData => ({
    headers: [
      { value: "orderNumber" },
      {
        value: "orderDate",
        type: "date",
        typeParameters: { dateType: "DD MMM YYYY HH:mm" },
      },
      { value: "source", sortable: false },
      { value: "name", sortable: false },
      { value: "city", sortable: false },
      { value: "salesOrderStatus" },
    ],
    selected: [],
    exports: [],
    filterComponent: SalesOrderFilter,
    defaultFilters: {},
    salesOrderData: [],
  }),
  computed: {
    ...mapGetters("authorisation", ["client", "isClient"]),
    ...mapGetters("crudTable", ["getRouteSetting"]),
    currentRouteSettings(): RouteSetting {
      return this.getRouteSetting(this.$route.name);
    },
  },
  watch: {
    client: {
      handler() {
        this.resetTable();
      },
      deep: true,
    },
  },
  created() {
    this.setDefaultFilters();
  },
  methods: {
    setDefaultFilters() {
      this.defaultFilters = {
        orderDateFrom: dayjs()
          .subtract(3, "month")
          .startOf("date")
          .format("YYYY-MM-DD HH:mm:ss"),
        orderDateTo: dayjs().endOf("date").format("YYYY-MM-DD HH:mm:ss"),
        salesOrderStatusId: [
          SalesOrderStatus.New,
          SalesOrderStatus.Alert,
          SalesOrderStatus.PickAndPack,
        ],
      };
    },
    selectItems(ids: number[]): void {
      if (!this.client?.id) throw "Selecting id's when clientId is not set"; //@TODO for now this function is only used when client is set

      this.selected = ids.map((id) => ({ id, clientId: this.client?.id })); //@TODO
    },
    async salesOrderIndex(data: PaginatedRequest) {
      if (!this.client?.id) throw "Client id is not set";
      const response = await clientSalesOrderIndex(data, this.client?.id);
      this.salesOrderData = response.data.data;
      this.exports = response.data.export ?? [];
      return response;
    },
    refreshTable(): void {
      (this.$refs.table as any).getData();
    },
    resetTable(): void {
      (this.$refs.table as any).handleReset();
    },
    async downloadExport() {
      const response = await exportSalesOrders(this.client.id);
      sendBlobToBrowserDownload("salesOrders", "xlsx", response.data);
    },
    handleDetail(item: SalesOrderIndexItem) {
      if (!this.client?.id) throw "Client id is not set";
      this.$router.push({
        name: "salesOrder.show",
        params: {
          salesOrderId: item.id.toString(),
          clientId: this.client?.id.toString(),
          salesOrders: JSON.stringify(
            this.salesOrderData.map((order) => ({
              id: order.id,
              orderNumber: order.orderNumber,
            }))
          ),
        },
      });
    },
    goToTracking(tracking: string | null) {
      if (!tracking) throw "No tracking data found";
      window.open(tracking, "_blank");
    },
    setItemClass(item: SalesOrderIndexItem) {
      if (item.stockStatus === SalesOrderStockStatus.Insufficient)
        return "red--text";
      if (item.stockStatus === SalesOrderStockStatus.PartiallyDeliverable)
        return "warning--text";
    },
  },
});
